import './bootstrap'
import '../css/app.css'

import { createSSRApp, h } from 'vue'
import { createInertiaApp, router, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import InlineSvg from 'vue-inline-svg'
import 'vue-tel-input/vue-tel-input.css'
import VueTelInput from 'vue-tel-input'
import VueTailwindDatepicker from 'vue-tailwind-datepicker'
import 'vue-final-modal/style.css'
import { createVfm } from 'vue-final-modal'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { i18nVue } from 'laravel-vue-i18n'
import { register } from 'swiper/element/bundle'
import { modal } from '/vendor/emargareten/inertia-modal'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
// import pkg from 'vue-social-sharing';
// const { ShareNetwork } = pkg;
import Vue3SocialSharingPlugin from "vue3-social-sharing";

import VueGtag from "vue-gtag";
import { createGtm } from '@gtm-support/vue-gtm';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
import ClientOnly from '@duannx/vue-client-only'

const appName = import.meta.env.VITE_APP_NAME || 'Immortalize'
const recaptchaSiteKey = import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY || null
const globalOptions = {
    mode: 'auto',
    defaultCountry: 'US',
}

const vfm = createVfm()
register()

const firebaseConfig = {
    apiKey: "AIzaSyDqFwqC2A80-1cvn6S1WffL0YYxdovhfKI",
    authDomain: "immortalize-a89ee.firebaseapp.com",
    databaseURL: "https://immortalize-a89ee.firebaseio.com",
    projectId: "immortalize-a89ee",
    storageBucket: "immortalize-a89ee.appspot.com",
    messagingSenderId: "528440335219",
    appId: "1:528440335219:web:aa173e519dc2ed72d4c306",
    measurementId: "G-0G3ZB4CK4X"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup({ el, App, props, plugin }) {
        return createSSRApp({ render: () => h(App, props) })
            .use(modal, {
                resolve: (name) =>
                    resolvePageComponent(
                        `./Pages/${name}.vue`,
                        import.meta.glob('./Pages/**/*.vue')
                    ),
            })
            .use(plugin)
            .use(ZiggyVue)
            .use(VueTelInput, globalOptions)
            .use(VueTailwindDatepicker)
            .use(vfm)
            .use(PerfectScrollbar)
            .use(Vue3SocialSharingPlugin)
            .use(VueReCaptcha, { siteKey: recaptchaSiteKey } )
            .use(i18nVue, {
                lang: props.initialPage.props.locale,
                fallbackLocale: 'en',
                resolve: lang => {
                    const langs = import.meta.glob('../../lang/*.json', {eager: true});
                    return langs[`../../lang/${lang}.json`].default;
                },
            })
            .use(VueGtag, {
                config: { id: "G-0G3ZB4CK4X" }
            })
            .use(createGtm({
                id: 'GTM-5T9H3X5C',
                defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
                enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: true, // Whether or not display console logs debugs (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            }))
            .component('ClientOnly', ClientOnly)
            .component('inline-svg', InlineSvg)
            .mixin({
                watch: {
                    '$page.url': {
                        immediate: true,
                        handler() {
                            const canonicalTag = document.querySelector('link[rel="canonical"]');
                            if (canonicalTag) {
                                canonicalTag.setAttribute("href", window.location.href);
                            }
                        }
                    }
                },
                methods: {
                    // Handle errors globally
                    onError(error) {
                        if (error.response && error.response.status === 404) {
                          // Handle 404 errors, for example redirect to a not found page
                          // this.$inertia.visit('/not-found');
                        } else {
                          // Handle other errors
                          console.error(error);

                          // Display a generic error message using toast or any other notification library
                          toast.error('An error occurred. Please try again.', {
                              autoClose: 5000,
                              position: toast.POSITION.TOP_RIGHT,
                          });
                      }
                  },
                },
            })
            .mount(el)
    },
    progress: {
        color: '#4B5563',
    },
})

const page = usePage()
router.on('success', () => {
    if (page.props.message) {
        toast.success(page.props.message, {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
        })
    }
})
router.on('error', (event) => {
    for (const error in event.detail.errors) {
        toast.error(event.detail.errors[error], {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
        })
    }
})
